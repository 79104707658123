.footerBar-Container {
  display: flex;
  width: 100vw;
  background-color: var(--eleColorDark);
  color: white;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.footerBar-info-Container {
  display: flex;
  gap: 16px;
}

.footerBar-Kontakt-Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
  background-color: rgba(39, 38, 39, 0.8);
  height: 150px;
}

.footerBar-Kontakt-List {
  display: flex;
  flex-basis: content;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 5px;
  text-align: start;
  align-items: start;
  height: 135px;
  width: 300px;
}

.footerBar-Kontakt-Header {
  font-size: 1rem;
  margin: 0.25vh 0.25vh;
  font-weight: bold;
  color: var(--eleColorLight);
}

.footerBar-Kontakt-Info {
  font-size: 0.9rem;
  width: fit-content;
  margin: 0.25vh;
  border-bottom: solid rgba(128, 128, 128, 0);
}

.footerBar-Kontakt-Info:hover {
  cursor: pointer;
  border-bottom: solid var(--eleColorLight);
}

.footerBar-Link {
  text-decoration: none;
  list-style: none;
  color: white;
}

@media (orientation: portrait) {
  .footerBar-info-Container {
    flex-direction: column;
  }
}
