.product-Nav-Container{
    z-index: 3;
    position: absolute;
    top: 87vh;
    border-radius: 5px;
    margin-bottom: 5vh;
    width: 100vw;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.product-Nav-List{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background:var(--eleColorDark);
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.322);
    border-radius: 5px;
    height: 60px;
}

.product-List-Btn{
    background-color: transparent;
    border: none;
    color: var(--eleColorLight);
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    width: 20vw;
    min-width: 250px;
    height: 60px;
    transition: 0.5s;
}

.product-List-Btn:hover{
    background: var(--eleColorLight);
    color: var(--eleColorDark);
    cursor: pointer;
}

@media (orientation: portrait){
    .product-Nav-List{
        flex-direction: column;
    }

}
