.product-Grid-Container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
  margin-left: 5vw;
  width: fit-content;
}

.product-Grid-Title {
  font-size: 3rem;
  color: var(--eleColorLight);
  background: var(--eleColorDark);
  width: 90vw;
  padding: 2.5vh 0 2.5vh 0;
  box-shadow: 5px 5px 15px var(--eleColorDark);
}

.product-Grid {
  width: 90vw;
  display: flex;
  gap: 4px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgb(245, 245, 245);
}

@media (max-width: 1213px) {
  .product-Grid-Title {
    margin-top: 15vh;
  }
}
