.product-Card-Container {
  position: relative;
  width: 33.1%;
  min-width: 480px;
  max-width: 575px;
  aspect-ratio: 1.5/1;
  color: white;
  justify-content: end;
  overflow: hidden;
  align-items: end;
}

.product-Card-Container:hover .product-Card {
  margin-top: 0px;
  height: 380px;
  backdrop-filter: blur(10px);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2));
  transition-delay: 0.3s;
}

.product-Card-Container:hover .product-Card-Title {
  color: var(--eleColorLight);
}

.product-Card-Container:hover .product-Card-Body {
  color: rgb(255, 255, 255);
  margin-top: 15%;
  backdrop-filter: blur(5px);
  visibility: visible;
}

.product-Card {
  width: 100%;
  height: 100%;
  position: absolute;
  margin-top: 50%;
  z-index: 2;
  background: transparent;
  display: flex;
  transition: 0.6s ease-out;
  background-color: rgba(0, 0, 0, 0);
  transition-delay: 0.4s;
  overflow: hidden;
}

.product-Card-ThumbNail {
  z-index: -1;
  width: 100%;
  height: 100%;
}

.product-Card-Title {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 2rem;
  font-weight: bold;
  width: fit-content;
  color: rgba(255, 255, 255, 0.856);
  transition: 1s;
}

.product-Card-Body {
  font-size: 1.1rem;
  height: fit-content;
  color: transparent;
  transition: 1.2s;
  width: 50%;
  min-width: 300px;
  text-align: start;
  margin-left: 16px;
  overflow: hidden;
  border-radius: 5px;
  backdrop-filter: blur(0.2px);
  padding: 8px;
  margin-top: 100px;
  visibility: hidden;
}

@media (orientation: portrait) {
  .product-Card-Body {
    font-size: 1rem;
  }

  .product-Card-Container {
    width: 90vw;
  }
}
