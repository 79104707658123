.navButton-Container{
  display: flex;
  flex-direction: row;
  padding: 8px;
  text-decoration: none;
}

.nav-Button {
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-size: 1.15rem;
  font-weight: 300;
  width: fit-content;
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  border-top: 5px solid rgba(255, 255, 255, 0);
  white-space: nowrap;
}


.nav-Button:hover{
  cursor: pointer;
  border-bottom: 5px solid rgb(85,34,181);
}

#KONTAKT:hover{
  border-bottom: 5px solid rgb(85,34,181);
}