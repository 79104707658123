body{
    overflow-x: clip;
    background-color: rgb(255, 255, 255);
  }
  
  .navBar-W-Container {
    top: 0;
    display: flex;
    position: sticky;
    flex-direction: row;
    background-color: var(--eleColorDark);
    z-index: 10;
    transition: 0.5s;
    width: cover;
  }
  
  .navBar-Logo{
    padding: 7px;
    margin-left: 5vw;
    width: 200px;
    height: 62px;
  }
  
  .button-List-Container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5vw;
  }
  
  .button-W-List{
    display: flex;
    gap: 24px;
    margin: 0;
    width: 45vw;
    padding-left: 10px;
    justify-content: flex-end;
  }

  .nav-W-Button{
    border: none;
    background-color: rgba(255, 255, 255, 0);
    font-size: 1.1rem;
    white-space: nowrap;
    padding: 4px 8px;
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    border-top: 5px solid rgba(255, 255, 255, 0);
    color:white;
    transition: border 0.2s;
  }

  .nav-W-Button:hover{
    border-bottom: 5px solid var(--eleColorLight);
    cursor: pointer;
  }

  .dd-Menu-Container{
    display: flex;
    justify-content: end;
    margin-right: 7vw;
    align-items: end;
    font-size: 2rem;
    color: var(--eleColorLight);
    align-self: center;
    width: 100vw;
  }
  
@media(orientation:portrait) {
  .button-List-Container{display: none;}
  .navBar-W-Container{
    width: 100vw;
    position: fixed;
  }
}

@media(orientation:landscape) {
  .dd-Menu-Container{
    display: none;
  }
}