.loading-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.loading-txt {
  font-size: 3rem;
  color: var(--eleColorLight);
  font-weight: bold;
}
