.product-Hero-Container {
  position: absolute;
  width: 100vw;
  height: 90vh;
  top: 0;
  z-index: 2;
  overflow-x: hidden;
}

.product-Hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90vh;
}

.product-Hero-Title {
  font-size: 5rem;
  margin-left: 75px;
  font-weight: bold;
  top: 25vh;
  width: fit-content;
  left: 5vw;
  color: transparent;
  background: var(--eleColorLight);
  background-clip: text;
}

.product-Hero-Body {
  font-size: 1.2rem;
  margin-left: 75px;
  text-align: start;
  width: 445px;
  font-weight: bold;
  top: 35vh;
  left: 5vw;
  color: white;
}

.product-Hero-Blur {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  width: 100vw;
  height: 90vh;
  position: absolute;
  background-color: rgba(48, 49, 116, 0.11);
  backdrop-filter: blur(5px);
  z-index: 2;
  overflow-x: clip;
}

@media screen and (max-width: 1200px) {
  .product-Hero-bg {
    width: 1200px;
  }
}

@media (orientation: portrait) {
  .product-Hero-Blur {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-Hero-Title {
    width: 90vw;
    font-size: 6vh;
    word-break: break-all;
    text-align: start;
    margin-left: 10px;
  }
  .product-Hero-Body {
    margin-left: 10px;
    width: 90vw;
  }
}
